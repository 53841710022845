<template>
  <div class="btn-wrapper text-lg">
    <a
      v-for="(social, index) in socialList"
      :key="index"
      :class="[
        'btn-white',
        'shadow',
        'rounded-circle',
        'icon-shape',
        index > 0 ? 'ml-1' : '',
      ]"
      :href="social.href"
      v-c-tooltip="{ content: social.label, placement: 'bottom' }"
      target="_blank"
      rel="noopener"
      :aria-label="social.label"
    >
      <Icon
        v-if="social.colorIcon"
        :icon="social.colorIcon"
        class="p-2"
        height="3rem"
        width="3rem"
      />
      <img
        v-else-if="social.colorImg"
        :src="social.colorImg"
        :alt="social.label"
        class="p-2"
        style="height: 3rem; width: 3rem"
      />
    </a>
  </div>
</template>

<script>
export const socialList = [
  {
    label: 'My GitHub',
    href: 'https://github.com/davidcochrum',
    color: 'dark',
    flatIcon: 'mdi:github-face',
    colorIcon: 'logos:github-octocat',
  },
  {
    label: 'My GitLab',
    href: 'https://gitlab.com/davidcochrum',
    color: 'warning',
    flatIcon: 'mdi:gitlab',
    colorIcon: 'logos:gitlab',
  },
  {
    label: 'My Linkedin',
    href: 'https://www.linkedin.com/in/davidcochrum/',
    color: 'primary',
    flatIcon: 'mdi:linkedin',
    colorIcon: 'logos:linkedin-icon',
  },
  {
    label: 'My Pluralsight Profile',
    href: 'https://app.pluralsight.com/profile/david-cochrum',
    color: 'dark',
    flatIcon: 'cib:pluralsight',
    colorImg: require('@/assets/images/pluralsight.svg'),
  },
  {
    label: 'My Resume PDF',
    href: "/David%20Cochrum's%20Resume.pdf",
    color: 'danger',
    flatIcon: 'mdi:file-pdf',
    colorIcon: 'fa-solid:file-pdf',
  },
]

export default {
  name: 'SocialLinks',

  data() {
    return {
      socialList,
    }
  },
}
</script>
