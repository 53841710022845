<template>
  <header class="header-global">
    <CNavbar
      id="navbar-main"
      class="navbar-main navbar-transparent"
      expand="lg"
      colorScheme="light"
    >
      <CContainer>
        <CNavbarBrand href="/" class="mr-lg-5">
          <h2 class="nav-title text-white d-none d-lg-inline">{{ title }}</h2>
          <h5 class="nav-title text-white d-lg-none pt-1">{{ title }}</h5>
        </CNavbarBrand>

        <CNavbarToggler
          class="navbar-toggler"
          aria-label="navbar_toggle"
          :aria-expanded="collapseIn"
          @click="collapseIn = true"
        >
          <span class="navbar-toggler-icon" />
        </CNavbarToggler>

        <CCollapse class="navbar-collapse" :visible="collapseIn">
          <div class="navbar-collapse-header">
            <CRow>
              <CCol class="collapse-brand" xs="6">
                <h3 class="nav-title text-black">
                  {{ title }}
                </h3>
              </CCol>
              <CCol class="collapse-close" xs="6">
                <CButton
                  @click="collapseIn = false"
                  color="info"
                  variant="ghost"
                >
                  <Icon icon="mdi:close" class="icon icon-sm" />
                </CButton>
              </CCol>
            </CRow>
          </div>

          <CNavbarNav class="align-items-lg-center ml-lg-auto">
            <CNavItem
              v-for="(social, index) in socialList"
              :key="index"
              class="nav-link-icon"
            >
              <CNavLink
                rel="noopener"
                :aria-label="social.label"
                :href="social.href"
                target="_blank"
                v-c-tooltip="{ content: social.label, placement: 'bottom' }"
              >
                <Icon :icon="social.flatIcon" height="2rem" />
                <span class="nav-link-inner--text d-lg-none ml-2">
                  {{ social.label }}
                </span>
              </CNavLink>
            </CNavItem>
          </CNavbarNav>
        </CCollapse>
      </CContainer>
    </CNavbar>
  </header>
</template>

<script>
import Headroom from 'headroom.js'
import { socialList } from './SocialLinks'

export default {
  name: 'Navigation',

  props: {
    title: { required: true, type: String },
  },

  data() {
    return {
      collapseIn: false,
      socialList,
    }
  },

  mounted() {
    const headroom = new Headroom(document.getElementById('navbar-main'))
    headroom.init()
  },
}
</script>
