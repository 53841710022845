import {
  CBadge,
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCardImage,
  CCardSubtitle,
  CCardText,
  CCardTitle,
  CCarousel,
  CCarouselItem,
  CCol,
  CCollapse,
  CContainer,
  CNav,
  CNavbar,
  CNavbarBrand,
  CNavbarNav,
  CNavbarToggler,
  CNavItem,
  CNavLink,
  CProgress,
  CProgressBar,
  CRow,
  CTabContent,
  CTabPane,
  CTooltip,
  vctooltip,
} from '@coreui/vue'

export default {
  install: (app) => {
    app.component(CBadge.name, CBadge)
    app.component(CButton.name, CButton)
    app.component(CCard.name, CCard)
    app.component(CCardBody.name, CCardBody)
    app.component(CCardHeader.name, CCardHeader)
    app.component(CCardImage.name, CCardImage)
    app.component(CCardSubtitle.name, CCardSubtitle)
    app.component(CCardText.name, CCardText)
    app.component(CCardTitle.name, CCardTitle)
    app.component(CCarousel.name, CCarousel)
    app.component(CCarouselItem.name, CCarouselItem)
    app.component(CCol.name, CCol)
    app.component(CCollapse.name, CCollapse)
    app.component(CContainer.name, CContainer)
    app.component(CNav.name, CNav)
    app.component(CNavbar.name, CNavbar)
    app.component(CNavbarBrand.name, CNavbarBrand)
    app.component(CNavbarNav.name, CNavbarNav)
    app.component(CNavbarToggler.name, CNavbarToggler)
    app.component(CNavItem.name, CNavItem)
    app.component(CNavLink.name, CNavLink)
    app.component(CProgress.name, CProgress)
    app.component(CProgress.name, CProgress)
    app.component(CProgressBar.name, CProgressBar)
    app.component(CRow.name, CRow)
    app.component(CTabContent.name, CTabContent)
    app.component(CTabPane.name, CTabPane)
    app.component(CTooltip.name, CTooltip)
    app.directive('c-tooltip', vctooltip)
  },
}
