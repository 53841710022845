<template>
  <CContainer class="section section-lg" data-aos="fade-up">
    <CRow>
      <CCol lg="6">
        <h1 class="h1">
          Proficiency
          <CButton
            component="a"
            color="success"
            role="button"
            href="https://app.pluralsight.com/profile/david-cochrum"
            target="_blank"
            class="ml-2"
            variant="outline"
            v-c-tooltip="{
              content:
                'View proficiency test results on my Pluralsight Profile',
              placement: 'top',
            }"
          >
            <Icon icon="bx:bx-link-external" class="icon icon-sm" />
          </CButton>
        </h1>

        <div
          v-for="(skill, index) in skillBars.values()"
          :key="index"
          @mouseenter="skillBarsAnimate = index"
          @mouseleave="
            skillBarsAnimate =
              skillBarsAnimate === index ? null : skillBarsAnimate
          "
          data-aos="fade-up"
        >
          <h5 class="progress-label">{{ skill.stack }}</h5>

          <CProgress :height="30">
            <CProgressBar
              color="success"
              :value="skill.value"
              :striped="skillBarsAnimate === index"
              :animated="skillBarsAnimate === index"
              ><strong>{{ skill.valueLabel }}</strong></CProgressBar
            >
          </CProgress>
        </div>
      </CCol>

      <CCol lg="6">
        <Lottie :options="lottieOptionsBuild" />
      </CCol>
    </CRow>
  </CContainer>
</template>

<script>
import * as lottieDataBuild from '@/assets/lottie/build.json'

export default {
  name: 'Proficiency',

  data() {
    return {
      lottieOptionsBuild: {
        autoplay: true,
        loop: true,
        animationData: lottieDataBuild.default,
      },
      skillBars: [
        {
          stack: 'PHP',
          value: 97,
          valueLabel: 'Expert: 97th Percentile',
        },
        {
          stack: 'NodeJS',
          value: 96,
          valueLabel: 'Expert: 96th Percentile',
        },
        {
          stack: 'MySQL',
          value: 93,
          valueLabel: 'Expert: 93rd Percentile',
        },
        {
          stack: 'JavaScript Core',
          value: 90,
          valueLabel: 'Expert: 90th Percentile',
        },
        {
          stack: 'React',
          value: 84,
          valueLabel: 'Expert: 84th Percentile',
        },
        {
          stack: 'Vue',
          value: 83,
          valueLabel: 'Expert: 83rd Percentile',
        },
      ],
      skillBarsAnimate: null,
    }
  },
}
</script>
