<template>
  <main>
    <div class="position-relative">
      <section class="section section-lg section-shaped pb-250">
        <div class="shape shape-style-1 bg-gradient-info">
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
          <span />
        </div>
        <CContainer class="py-lg-md d-flex">
          <div class="col px-0">
            <CRow>
              <CCol lg="6">
                <h1 class="display-3 text-white">
                  Software Engineer specializing in PHP + JS
                </h1>
                <p class="lead text-white">
                  Good isn’t good enough. I apply this principle to myself just
                  as much as my work since I am never satisfied with the “same
                  old, same old.” Through constant, avid exploration of emerging
                  techniques and technologies, I strive to produce the cleanest,
                  most efficient, objective, and reusable code that meets or
                  even exceeds project requirements.
                </p>

                <SocialLinks />
              </CCol>

              <CCol lg="6">
                <lottie :options="lottieOptionsCoding" />
              </CCol>
            </CRow>
          </div>
        </CContainer>

        <!-- SVG separator -->
        <div class="separator separator-bottom separator-skew">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon class="fill-white" points="2560 0 2560 100 0 100" />
          </svg>
        </div>
      </section>
      <!-- 1st Hero Variation -->
    </div>
  </main>
</template>

<script>
import Lottie from 'vue-lottie'
import SocialLinks from '@/components/SocialLinks'

export default {
  name: 'Greeting',

  components: {
    Lottie,
    SocialLinks,
  },

  data() {
    return {
      lottieOptionsCoding: {
        autoplay: true,
        loop: true,
        animationData: require('@/assets/lottie/coding.json'),
      },
    }
  },
}
</script>
