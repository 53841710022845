<template>
  <div>
    <Navigation title="David Cochrum" />
    <Greeting />
    <Skills />
    <Proficiency />
    <Experience />
    <Education />
  </div>
</template>

<script>
import Education from '@/components/Education'
import Experience from '@/components/Experience'
import Greeting from '@/components/Greeting'
import Navigation from '@/components/Navigation'
import Proficiency from '@/components/Proficiency'
import Skills from '@/components/Skills'

import '@coreui/coreui/dist/css/coreui.min.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import '@/assets/css/argon-design-system-react.css'

export default {
  name: 'App',

  components: {
    Education,
    Experience,
    Greeting,
    Navigation,
    Proficiency,
    Skills,
  },
}
</script>
