<template>
  <section class="section section-lg">
    <CContainer data-aos="fade-left">
      <div class="d-flex px-3">
        <div>
          <div
            class="icon-shape bg-gradient-white shadow rounded-circle text-info"
          >
            <Icon class="text-info" icon="mdi:books" />
          </div>
        </div>

        <div class="pl-4">
          <h4 class="display-3 text-info">Education</h4>
        </div>
      </div>

      <CRow class="row-grid align-items-center">
        <CCol
          v-for="(info, index) in educationInfo"
          :key="index"
          class="order-lg-1"
          lg="6"
          data-aos="fade-up"
        >
          <CCard class="card-lift--hover shadow mt-4">
            <CCardBody>
              <div class="d-flex px-3">
                <div class="pl-4">
                  <h5 class="text-info">
                    <img
                      class="icon icon-md"
                      :src="info.schoolLogo"
                      :alt="info.schoolName"
                    />
                    {{ info.schoolName }}
                  </h5>

                  <h6>{{ info.subHeader }}</h6>

                  <CBadge color="info" class="mr-1">
                    {{ info.duration }}
                  </CBadge>

                  <p class="description mt-3">{{ info.desc }}</p>
                </div>
              </div>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>
    </CContainer>

    <div class="separator separator-bottom separator-skew zindex-100">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="none"
        version="1.1"
        viewBox="0 0 2560 100"
        x="0"
        y="0"
      >
        <polygon class="fill-white" points="2560 0 2560 100 0 100" />
      </svg>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Education',

  data() {
    return {
      educationInfo: [
        {
          schoolLogo: require('@/assets/images/kaplan.svg'),
          schoolName: 'Kaplan University',
          subHeader: "Earned Bachelor's in Information Technology",
          duration: 'January 2010 - November 2011',
          desc: 'Graduated Summa Cum Laude with an emphasis on Web Programming.',
        },
        {
          schoolLogo: require('@/assets/images/broward.svg'),
          schoolName: 'Broward College',
          subHeader: 'Information Technology',
          duration: 'January 2006 - December 2008',
          desc: 'Took various general education courses and shifted focus to Information Technology.',
        },
        {
          schoolLogo: require('@/assets/images/ucf.svg'),
          schoolName: 'University of Central Florida',
          subHeader: 'Computer & Mechanical Engineering',
          duration: 'September 2004 - May 2005',
          desc: 'Left the nest for my first year of college aiming for a degree in Computer or Mechanical Engineering.',
        },
      ],
    }
  },
}
</script>

<style scoped></style>
