<template>
  <CContainer class="my-5 section section-lg" data-aos="fade-up">
    <h1 class="h1 text-center">My Specialities</h1>
    <p class="lead text-center">
      The list constantly grows, but these are a few of my strong suits:
    </p>
    <CRow>
      <CCol lg="6">
        <lottie :options="lottieOptionsWebDev" />
      </CCol>

      <CCol lg="6">
        <div class="d-flex justify-content-center flex-wrap mb-5">
          <div
            v-for="specialty in specialtyIcons"
            :key="specialty.name"
            class="shadow rounded-circle mb-5 icon-shape"
            v-c-tooltip="{ content: specialty.name, placement: 'bottom' }"
            data-aos="fade-up"
          >
            <Icon
              :icon="specialty.icon"
              :alt="specialty.name"
              class="p-2"
              height="4rem"
              width="4rem"
            />
          </div>
        </div>

        <CRow
          v-for="(bullet, index) in skillBullets"
          :key="index"
          data-aos="fade-up"
        >
          <CCol xs="1">
            <Icon
              :icon="bullet.iconSrc"
              :alt="bullet.iconAlt"
              height="2rem"
              width="2rem"
            />
          </CCol>
          <CCol>
            <p>{{ bullet.text }}</p>
          </CCol>
        </CRow>
      </CCol>
    </CRow>
  </CContainer>
</template>

<script>
export default {
  name: 'Skills',

  data() {
    return {
      lottieOptionsWebDev: {
        autoplay: true,
        loop: true,
        animationData: require('@/assets/lottie/webdev.json'),
      },
      skillBullets: [
        {
          text: 'Build rock-solid applications and libraries in PHP and Javascript',
          iconAlt: 'rock',
          iconSrc: 'noto:rock',
        },
        {
          text: 'Emphasize abundant test coverage minimizing bugs and maximizing stability',
          iconAlt: 'goggles',
          iconSrc: 'noto:goggles',
        },
        {
          text: 'Coach juniors while deepening learning from peers and exploration',
          iconAlt: 'light bulb',
          iconSrc: 'noto:light-bulb',
        },
      ],
      specialtyIcons: [
        { name: 'PHP', icon: 'vscode-icons:file-type-php3' },
        { name: 'Laravel', icon: 'logos:laravel' },
        { name: 'Symfony', icon: 'logos:symfony' },
        {
          name: 'Unit & Integration Testing',
          icon: 'file-icons:test-generic',
        },
        {
          name: 'Object-Oriented Programming (OOP)',
          icon: 'icon-park:layers',
        },
        { name: 'JavaScript', icon: 'logos:javascript' },
        { name: 'TypeScript', icon: 'logos:typescript-icon' },
        { name: 'NodeJS', icon: 'logos:nodejs-icon' },
        { name: 'Vue', icon: 'logos:vue' },
        { name: 'React', icon: 'logos:react' },
        { name: 'MySQL', icon: 'logos:mysql' },
        { name: 'Snowflake', icon: 'twemoji:snowflake' },
        { name: 'AWS', icon: 'logos:aws' },
        { name: 'Git', icon: 'logos:git-icon' },
        { name: 'Docker', icon: 'logos:docker-icon' },
      ],
    }
  },
}
</script>
