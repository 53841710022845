<template>
  <section class="section pb-0 bg-gradient-info my-5">
    <CContainer data-aos="fade-up-later">
      <div class="d-flex px-3">
        <div>
          <div
            class="icon-shape bg-gradient-white shadow rounded-circle text-info"
          >
            <Icon class="text-info" icon="mdi:briefcase-variant" />
          </div>
        </div>

        <div class="pl-4">
          <h4 class="display-3 text-white">Experience</h4>
        </div>
      </div>

      <CRow class="row-grid align-items-center">
        <ExperienceCard
          card-header-id="company-color-cw24"
          role="Technology Consultant"
          company="Cornel West 2024 Campaign"
          date="January 2024 – Present"
          description-summary="I've been able to build various tools and interactive components which save the campaign significant time and money."
          :description-bullets="[
            'Built interactive state map to display ballot access progress across the US. Work completed below budget and quite ahead of schedule.',
            'Created a bot to help fellow moderators of the campaign Slack streamline repetitive tasks.',
          ]"
        >
          <template v-slot:logo>
            <img
              :class="logoClasses"
              src="@/assets/images/cw24.svg"
              alt="Cornel West 2024 logo"
              rel="company-color-cw24"
              @load="setBgColor"
            />
          </template>
        </ExperienceCard>

        <ExperienceCard
          card-header-id="company-color-yahoo"
          role="Back End Engineer (Contract)"
          company="Yahoo Fantasy Sports"
          date="November 2022 – December 2023"
          description-summary="With a monolithic app containing code stretching back 20+ years, some modern touches were way overdue."
          :description-bullets="[
            'Modernize all aspects of PHP within monolithic application with latest open source standards and practices.',
            'Create next generation of API utilizing Slim framework and GraphQL.',
            'Fix bugs with existing features while increasing test coverage.',
          ]"
        >
          <template v-slot:logo>
            <img
              :class="logoClasses"
              src="@/assets/images/yahoo-fantasy.svg"
              alt="Yahoo Fantasy Sports logo"
              rel="company-color-yahoo"
              @load="setBgColor"
            />
          </template>
        </ExperienceCard>

        <ExperienceCard
          card-header-id="company-color-curio"
          role="Lead Software Engineer"
          company="Curio NFT"
          date="June 2021 – May 2023"
          description-summary="In our attempt to bring NFTs to the masses, my main responsibility is to build and maintain the Curio web app backend."
          :description-bullets="[
            'Integrate various web3 APIs to push/pull NFT and blockchain data.',
            'Build Laravel based Shopify app to connect with Curio platform.',
            'Engineer unified NodeJS API for custodial minting on Polygon and Ripple blockchains.',
            'Add and update features on CodeIgniter based E-Commerce platform.',
          ]"
        >
          <template v-slot:logo>
            <img
              :class="logoClasses"
              src="@/assets/images/curio.png"
              alt="CurioNFT logo"
              rel="company-color-curio"
              @load="setBgColor"
            />
          </template>
        </ExperienceCard>

        <ExperienceCard
          card-header-id="company-color-ninjacat"
          role="Software Engineer"
          company="NinjaCat"
          date="December 2018 – May 2021"
          description-summary="As we strove to constantly improve reporting performance, my main responsibility was to build and maintain NinjaCat's reporting engine."
          :description-bullets="[
            'Maintained multiple projects from monoliths to microservices using Laravel or Lumen on the backend and VueJS or APIs on the frontend.',
            'Introduced regular code reviews, industry standards and best practices while emphasizing growth of automated test coverage required to pass builds.',
            'Finished and maintained VOIP microservice handling dynamic routing of hundreds of incoming voice calls per minute via Twilio and Plivo APIs.',
            'Rebuilt Docker containers used by engineers providing vastly improved daily experience.',
            'Created multiple internal and client facing tools to replace various manual processes.',
            'Hosted a handful of weekly company-wide social gatherings.',
          ]"
        >
          <template v-slot:logo>
            <img
              :class="logoClasses"
              src="@/assets/images/ninjacat.svg"
              alt="NinjaCat logo"
              rel="company-color-ninjacat"
              @load="setBgColor"
            />
          </template>
        </ExperienceCard>

        <ExperienceCard
          card-header-id="company-color-sonnys"
          role="Senior PHP Developer"
          company="Sonny's CarWash Factory"
          date="May 2015 – December 2018"
          description-summary='I started out quite junior at app development with only a dictionary definition of "MVC" and grew to a senior position while passing along my newfound knowledge to newer team members.'
          :description-bullets="[
            'Worked on complete overhaul of legacy procedural PHP conversion to Symfony OOP/MVC.',
            `Co-architected and wrote the industry's first cloud served web interface for tunnel hardware (PLC) controller utilizing resilient NodeJS microservices pushing and pulling messages up and down via RabbitMQ.`,
            'Started as junior and moved up to senior while mentoring other junior members.',
            'Wrote MySQL database migration scripts in SQL or using an in-house Java migration tool.',
            'Regularly participated in interviewing potential engineering hires.',
          ]"
        >
          <template v-slot:logo>
            <img
              :class="logoClasses"
              src="@/assets/images/sonnys.svg"
              alt="Sonny's CarWash Factory logo"
              rel="company-color-sonnys"
              @load="setBgColor"
            />
          </template>
        </ExperienceCard>

        <ExperienceCard
          card-header-id="company-color-macate"
          role="Senior Web Developer"
          company="Macate Group"
          date="June 2014 – May 2015"
          :description-bullets="[
            'Solely responsible for maintaining, expanding, debugging, and improving API (extends WordPress XML-RPC) and accompanying documentation utilized by all mobile and desktop apps produced by the group of companies.',
            'Created my first Laravel MVC/OOP project serving a multi-tenant VOIP SaaS.',
          ]"
        >
          <template v-slot:logo>
            <img
              :class="logoClasses"
              src="@/assets/images/macate.svg"
              alt="Macate logo"
              rel="company-color-macate"
              @load="setBgColor"
            />
          </template>
        </ExperienceCard>

        <ExperienceCard
          card-header-id="company-color-calvary"
          role="IT & Web Administrator / Production Director"
          company="Calvary Chapel Sawgrass"
          date="March 2006 – June 2014"
          description-summary="Served as project manager, lead developer and/or team member on dozens of application development projects."
          :description-bullets="[
            'Started out with self-taught, hand coded PHP and JavaScript before graduating to WordPress for building and maintaining websites.',
            'Maintain all the organization’s computers, devices, servers, and telecommunications.',
            'Recruit, train, and direct production technical team for church services and video projects.',
          ]"
        >
          <template v-slot:logo>
            <img
              :class="logoClasses"
              src="@/assets/images/calvary.jpg"
              alt="Calvary Chapel Sawgrass logo"
              rel="company-color-calvary"
              @load="setBgColor"
            />
          </template>
        </ExperienceCard>
      </CRow>
    </CContainer>

    <div class="separator separator-bottom separator-skew zindex-100">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="none"
        version="1.1"
        viewBox="0 0 2560 100"
        x="0"
        y="0"
      >
        <polygon class="fill-white" points="2560 0 2560 100 0 100" />
      </svg>
    </div>
  </section>
</template>

<script>
import ExperienceCard from '@/components/ExperienceCard.vue'
import ColorThief from 'colorthief'

const colorThief = new ColorThief()

export default {
  name: 'Experience',
  components: { ExperienceCard },

  data() {
    return {
      logoClasses: ['img-fluid'],
    }
  },

  methods: {
    setBgColor: (event) => {
      const img = event.target
      const colors = colorThief.getColor(img)
      const rel = document.getElementById(img.getAttribute('rel'))
      if (rel) {
        rel.style.backgroundColor = `rgb(${colors.join(', ')})`
      }
    },
  },
}
</script>
