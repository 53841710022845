<template>
  <CCol lg="4" data-aos="fade-left">
    <CCard class="shadow-lg--hover shadow border-0 text-center rounded d-flex">
      <CCardHeader :id="`${cardHeaderId}`">
        <h5 class="text-white">{{ company }}</h5>
      </CCardHeader>

      <CCardBody class="py-5">
        <div
          class="mb-3 img-center img-fluid"
          style="width: 100px; height: 100px"
        >
          <slot name="logo" />
        </div>

        <CCardTitle tag="h5">{{ role }}</CCardTitle>
        <CCardSubtitle>{{ date }}</CCardSubtitle>

        <CCardText class="description my-3 text-left">
          {{ descriptionSummary }}
          <ul>
            <li
              v-for="(desc, descIndex) in descriptionBullets"
              :key="descIndex"
            >
              {{ desc }}
            </li>
          </ul>
        </CCardText>
      </CCardBody>
    </CCard>
  </CCol>
</template>

<script>
export default {
  name: 'ExperienceCard',

  props: {
    cardHeaderId: String,
    company: String,
    companyLogoClasses: { required: false, type: Array },
    role: String,
    date: String,
    descriptionSummary: { required: false, type: String },
    descriptionBullets: { required: true, type: Array },
  },

  data: function () {
    return {
      companySlug: this.company.toLowerCase().replace(/ /g, '-'),
    }
  },
}
</script>
